import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const hls = require('videojs-contrib-hls')
Vue.use(hls)
Vue.config.productionTip = false
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '1uDsFzpNYs2wGTtnucCt9BjBiFSSfB8h'
})
// 引入 Vconsole
import Vconsole from 'vconsole'
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)
// 所有环境均使用
new Vconsole()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
