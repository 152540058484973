<template>
  <div id="app">
    <div>{{ content }}</div>
    <div id="mapDiv" style="width: 500px; height: 500px"></div>
    <!-- <div id="mapDiv1" style="width: 500px; height: 500px"></div> -->
    <button style="width: 100px; height: 100px" @click="open">打开</button>
  </div>
</template>

<script>
import { MP } from "@/utils/map";
// import Map from "@/components/Map.vue";
export default {
  data() {
    return {
      ak: "1uDsFzpNYs2wGTtnucCt9BjBiFSSfB8h",
      content: "",
    };
  },
  components: {
    // Map,
  },
  mounted() {},
  methods: {
    open() {
      ue4("SwitchLatLng", true);
      this.getLocation()
      setInterval(() => {
        this.getLocation()
      }, 1000);
    },

    // getLocation() {
    // //address：要查询的地理位置  


    // },
    onLoad(lat, lng) {
      var map;
      var zoom = 18;
      //初始化地图对象
      map = new T.Map("mapDiv");
      //设置显示地图的中心点和级别
      map.centerAndZoom(new T.LngLat(lat, lng), zoom);

      //创建标注对象
      var marker = new T.Marker(new T.LngLat(lat, lng));
      //向地图上添加标注
      map.addOverLay(marker);
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        this.content = "当前浏览器不支持地理定位";
      }
    },
    showPosition(position) {
      this.content =
        "Latitude(纬度): " +
        position.coords.latitude + // 纬度
        "<br>Longitude(经度): " +
        position.coords.longitude; // 经度
      // ue4("lat", position.coords.latitude);
      // ue4("lng", position.coords.longitude);
      let data = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      console.log(data);
      ue4("SetLatLng", data);
      this.onLoad(position.coords.longitude, position.coords.latitude);
    },

    showError(error) {
      console.log(error);
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.content = "用户拒绝地理定位请求";
          break;
      }
    },
  },
};
</script>

<style>
</style>