<template>
  <div class="hello">
    <videoPlayer
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptionsOne"
    >
    </videoPlayer>
    <div id="chart2" ref="chart2" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
// 根据官方文档配置发现没有效果
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      playerOptionsOne: {
        controls: true,
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "application/x-mpegURL", // 类型
            src: "https://61.175.188.134:6014/openUrl/wtd4cIo/live.m3u8", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false, // 是否显示全屏按钮
        },
      },
      charts2: null,
    };
  },
  components: { videoPlayer },
  mounted() {
    if (this.charts2 == null) {
      this.charts2 = echarts.init(document.getElementById("chart2"), null, {
        renderer: "canvas",
      });
    }
    setTimeout(() => {
      this.initData8();
    }, 60);
  },
  methods: {
    initData8() {
      let yData = [90, 555, 666, 999, 567, 999, 888, 0],
        xData = ["测1", "测2", "测3", "测4", "测5", "测6", "测7", "测8"],
        datacoords = [];
      for (var i = 0; i < xData.length; i++) {
        datacoords.push([
          {
            coord: [i, yData[i]],
          },
          {
            coord: [i + 1, yData[i + 1]],
          },
        ]);
      }
      this.charts2.setOption({
        animation: true, //控制动画示否开启
        animationDuration: 3000,
        animationEasing: "bounceOut", //缓动动画
        animationThreshold: 8, //动画元素的阈值
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.5)",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "rgba(0,0,0,.5)",
            },
          },
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        grid: {
          left: "3%", //图表距边框的距离
          right: "3%",
          top: "15%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            nameGap: 3,
            nameTextStyle: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12,
            },
            type: "category",
            data: xData,
            boundaryGap: false, //从0开始
            axisLine: {
              onZero: true,
              rotate: 30, //坐标轴内容过长旋转
              interval: 1,
              lineStyle: {
                color: "#636E7C",
              },
            },
            axisLabel: {
              color: "rgba(255,255,255,.8)", //坐标的字体颜色
              fontSize: 12,
            },
            axisTick: {
              //坐标轴刻度颜色  x和y不交叉
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: "个",
            min: 0,
            max: function (value) {
              return Math.ceil(value.max / 5) * 5;
            },
            splitNumber: 5,
            type: "value",
            nameTextStyle: {
              color: "rgba(255,255,255,.89)",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.25)",
                type: "dashed",
              },
            },
            axisTick: {
              //坐标轴刻度颜色
              show: false,
            },
            axisLine: {
              //坐标轴线颜色
              show: true,
              lineStyle: {
                color: "#636E7C",
              },
            },
            axisLabel: {
              color: "rgba(255,255,255,.8)", //坐标的字体颜色
              fontSize: 12,
            },
          },
        ],
        series: [
          {
            name: "苏苏小苏苏",
            type: "line",
            smooth: false,
            lineStyle: {
              color: "aqua",
              width: 1.5,
              type: "solid",
              shadowOffsetX: 0, // 折线的X偏移
              shadowOffsetY: 0, // 折线的Y偏移
              shadowBlur: 4, // 折线模糊
              shadowColor: "rgba(255, 255, 255, 0.8)", //设置折线阴影颜色
            },
            showSymbol: true, //是否默认展示圆点
            symbol: "circle", // 默认是空心圆（中间是白色的）
            // symbolSize: 7,
            // itemStyle: {
            //   color: "#021E47", //实圆的背景色
            //   borderWidth: 1,
            //   borderColor: "aqua",
            // },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: "rgba(0, 255, 255, .7)",
                },
                {
                  offset: 0.74,
                  color: "rgba(0, 170, 255, 0.3)",
                },
                {
                  offset: 0,
                  color: "rgba(220,120,40,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: yData,
          },
          {
            showSymbol: false,
            name: "苏苏小苏苏",
            type: "lines",
            polyline: false,
            smooth: false,
            coordinateSystem: "cartesian2d",
            zlevel: 1,
            effect: {
              show: true,
              smooth: true,
              period: 2,
              symbolSize: 4,
            },
            lineStyle: {
              color: "#fff",
              width: 10,
              opacity: 0,
              curveness: 0,
              cap: "round",
            },
            data: datacoords,
          },
        ],
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.chart {
  width: 600px;
  height: 400px;
}
</style>
