<template>
  <div>
    <div id="max">
      <!-- <canvas
        v-show="isCanvas"
        id="canvas"
        width="1920"
        height="1080"
        style="z-index: 999"
      ></canvas>
      <img v-show="isImgs" src="@/assets/imgs/1_00418.png" alt="" /> -->
      <div class="video-box">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isImgs: false,
      isCanvas: true,
      player: "",
      vUrl: "https://union.huaxindata.com.cn/external/#/videoControl?organizationId=bQrZPOm8wyI=&autoPlay=ymjmmzG9bC4=&deviceId=zZihKo1WYmw=&dvrId=Xh0lAMksODs=",
    };
  },
  mounted() {
    // console.log(this);
    // let max = document.getElementById("max");
    // //初始化参数
    // let that = this;
    // var canvas = null;
    // var ctx = null;
    // var imageNow = 0;
    // var timer = null;
    // var sources = [];
    // //预加载序列图片
    // function loadImages(sources, callback) {
    //   var loadedImages = 0;
    //   var numImages = 0;
    //   var images = [];
    //   var clearWidth = canvas.width;
    //   var clearHeight = canvas.height;
    //   // get num of sources
    //   numImages = sources.length;
    //   for (var i = 0, len = sources.length; i < len; i++) {
    //     images[i] = new Image();
    //     //当一张图片加载完成时执行
    //     images[i].onload = function () {
    //       //当所有图片加载完成时，执行回调函数callback
    //       if (++loadedImages >= numImages) {
    //         callback(images);
    //       }
    //     };
    //     //把sources中的图片信息导入images数组
    //     images[i].src = sources[i];
    //     //            console.log(images);
    //   }
    // }
    // //播放图片动画
    // function playImages(images) {
    //   var imageNum = images.length;
    //   timer = setInterval(function () {
    //     //      ctx.fillStyle="rgba(0,0,0,0)";
    //     ctx.clearRect(0, 0, 1920, 1080);
    //     // ctx.fillRect(0, 0, 1920, 1080);
    //     ctx.drawImage(images[imageNow], 0, 0, 1920, 1080);

    //     imageNow++;
    //     if (imageNow >= imageNum) {
    //       imageNow = 0;
    //     }
    //   }, 80);
    // }
    // //初始化
    // window.onload = function () {
    //   //构建图片序列数据
    //   for (var i = 0; i < 500; i++) {
    //     // sources[i] = "imgs/1_" + i + ".png"; //根据项目修改
    //     if (i < 10) {
    //       sources[i] = require("../assets/imgs/1_0000" + i + ".png");
    //     }
    //     if (i >= 10 && i < 100) {
    //       sources[i] = require("../assets/imgs/1_000" + i + ".png");
    //     }
    //     if (i >= 100) {
    //       sources[i] = require("../assets/imgs/1_00" + i + ".png");
    //     }
    //   }
    //   canvas = document.getElementById("canvas");
    //   console.log(canvas);
    //   canvas.width = 1920;
    //   canvas.height = 1080;

    //   ctx = canvas.getContext("2d");
    //   //ctx.globalAlpha=0.5
    //   //执行图片预加载，加载完成后执行main
    //   loadImages(sources, function (images) {
    //     playImages(images);
    //   });
    //   max.onmouseover = (e) => {
    //     console.log(e + "移入");
    //     e.stopPropagation();
    //     clearInterval(timer);
    //     that.isImgs = true;
    //     canvas = null;
    //     that.isCanvas = false;
    //     imageNow = 417;
    //     return;
    //   };
    //   max.onmouseout = (e) => {
    //     console.log(e + "移出");
    //     if (that.isImgs) {
    //       e.stopPropagation();
    //       clearInterval(timer);
    //       canvas = document.getElementById("canvas");
    //       that.isCanvas = true;
    //       that.isImgs = false;
    //       loadImages(sources, function (images) {
    //         playImages(images);
    //       });
    //     }
    //   };
    // };
    // this.doVideo();
  },
  methods: {
   
  },
};
</script>

<style>
#max {
  width: 1920px;
  height: 1080px;
  background-color: transparent;
}
#canvas {
  z-index: 999;
}
body {
  /*z-index: -9999;*/
  /*background: rgba(48, 42, 42, 0);*/
}
</style>